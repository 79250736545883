import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ConfigTravel from '../components/config-travel/config-travel';
import Map from '../components/map/map';
import '../styles/main.scss';
import PlanPage from './plan';
import ContactPage from './contact';
// import SummaryPage from './summary';
import Route from './route';
import { useStaticQuery, graphql } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { navigate } from 'gatsby';

import { Typography, Layout, Button, Row, Col, Steps, Space } from 'antd';

import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

const { Text } = Typography;

const { Step } = Steps;

const steps = [
  {
    content: <Route />,
    title: 'Seleccionar ruta',
    canContinue: ({ locations }) => {
      console.log(locations);
      return !!locations && !!locations.from && !!locations.to;
    },
  },
  {
    content: <ConfigTravel />,
    title: 'Ruta de viaje',
    canContinue: ({ route, travelDate, transport }) => {
      return !!route && !!travelDate && !!transport;
    },
  },
  {
    content: <PlanPage />,
    title: 'Seleccionar plan',
    canContinue: ({ plan }) => {
      return !!plan;
    },
  },
  {
    content: <ContactPage></ContactPage>,
    title: 'Contactos de emergencia',
    canContinue: ({ contacts }) => {
      console.log('DEBUG contacts ---> ', contacts);
      return !!contacts && contacts.length;
    },
  },
];

const totalSteps = steps.length - 1;

const { Header, Content, Footer } = Layout;

const geoip = GEOIP_URL => {
  const config = {
    method: 'get',
    url: GEOIP_URL,
  };

  return axios(config)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
    });
};

const WizardPage = ({ location }) => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const redirect = location.pathname.split('/').pop();

  const configObject = {};

  const configQuery = useStaticQuery(graphql`
    query config {
      allStrapiConfig {
        nodes {
          id
          name
          value
        }
      }
    }
  `);

  const { route, travelDate, transport, plan, isAuthenticated, locations, contacts } = useSelector(
    state => state
  );
  const options = { route, travelDate, transport, plan, locations, contacts };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { state: { redirect } });
    }
  }, [isAuthenticated, redirect]);

  useEffect(() => {
    const config = configQuery.allStrapiConfig.nodes;
    config.forEach(c => {
      configObject[c.name] = c.value;
    });

    dispatch({
      type: 'SET_CONFIG',
      data: configObject,
    });

    async function fetchdata() {
      const currentLocation = await geoip(configObject.GEOIP_URL);
      dispatch({
        type: 'SET_CURRENT_LOCATION',
        data: currentLocation,
      });
    }

    fetchdata();
  }, []);

  const goToSummary = () => {
    console.log(plan);
    navigate(`/summary/${plan}`);
  };

  return (
    <Layout>
      <div className={current !== 1 ? 'hidden map-main-container' : 'map-main-container'}>
        <Map
          className={'map'}
          padding={{
            bottom: 300,
            top: 100,
            left: 50,
            right: 50,
          }}></Map>
      </div>
      <Col span={24} className="background-base">
        <Header className="site-layout-background background-base">
          <Space size="middle" direction="horizontal">
            {current === 0 && (
              <Button
                style={{ textAlign: 'left' }}
                className="icon-color-primary"
                type="text"
                size="large"
                href="/home"
                onClick={() => setCurrent(current - 1)}
                icon={<ArrowLeftOutlined />}
              />
            )}
            {current > 0 && (
              <Button
                style={{ textAlign: 'left' }}
                className="icon-color-primary"
                size="large"
                type="text"
                onClick={() => setCurrent(current - 1)}
                icon={<ArrowLeftOutlined />}
              />
            )}
            <Text strong>{steps[current].title}</Text>
          </Space>
        </Header>
      </Col>

      <Content className="site-layout-background" style={{ minHeight: '76vh' }}>
        <div>{steps[current].content}</div>
      </Content>

      <Footer className="background-base">
        <Row justify="center" align="middle">
          <Col span={18} offset={1}>
            <div className="content-dots">
              {current <= totalSteps && (
                <Steps current={current} progressDot>
                  {steps.map((item, i) => (
                    <Step key={i} className="content-dots" />
                  ))}
                </Steps>
              )}
            </div>
          </Col>
          {!!steps[current].canContinue(options) && (
            <Col span={5}>
              <div className="steps-action">
                <Button
                  className="icon-color-base"
                  type="primary"
                  shape="circle"
                  icon={<ArrowRightOutlined />}
                  size="large"
                  onClick={() => {
                    if (current === totalSteps) {
                      goToSummary();
                    } else {
                      setCurrent(current + 1);
                    }
                  }}
                />
              </div>
            </Col>
          )}
        </Row>
      </Footer>
    </Layout>
  );
};

WizardPage.propTypes = {
  data: PropTypes.any,
  location: PropTypes.object,
};

WizardPage.defaultProps = {
  data: '',
};

export default WizardPage;
