import React, { useState, useEffect } from 'react';
import '../styles/contact.scss';
import { Switch, Row, Layout, Col, Form, Input, Button, Typography } from 'antd';
import {
  UserOutlined,
  PlusOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useDispatch, useSelector } from 'react-redux';
import EmptyState from '../images/emptystate.svg';

const { Content } = Layout;

const { Title, Text, Paragraph } = Typography;

const ContactPage = () => {
  const dispatch = useDispatch();

  const [showCF, setShowCF] = useState(false);
  const { user, contacts: selected_contacts } = useSelector(state => state);

  const queryParams = {
    id: user.id,
  };

  const { loading, data } = useQuery(GET_USER_CONTACTS, {
    variables: queryParams,
  });

  const [addContact] = useMutation(SAVE_USER_CONTACT, {
    update(
      cache,
      {
        data: {
          createContact: { contact: new_contact },
        },
      }
    ) {
      cache.modify({
        fields: {
          contacts(existingContacts = []) {
            const newContactRef = cache.writeFragment({
              data: new_contact,
              fragment: gql`
                fragment NewContact on Contact {
                  id
                  phone
                  address
                  fullname
                }
              `,
            });
            return [...existingContacts, newContactRef];
          },
        },
      });
    },
  });

  const putContact = async contact => {
    try {
      const dup = data && data.contacts.includes(contact);

      if (!dup) {
        addContact({
          variables: { contact: { data: { ...contact, user: user.id } } },
        });
        setShowCF(false);
      }
    } catch (e) {
      console.log('DEBUG error', e);
    }
  };

  const checkSelected = contact => {
    return selected_contacts.includes(contact);
  };

  const toggleContact = contact => {
    console.log('DEBUG ', selected_contacts, contact);

    if (!selected_contacts.includes(contact)) {
      dispatch({
        type: 'ADD_CONTACT',
        data: contact,
      });
    } else {
      dispatch({
        type: 'REMOVE_CONTACT',
        data: contact,
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: 'RESET_CONTACTS',
    });
  }, []);

  return (
    <Layout className="background-base">
      <Content>
        <Row>
          <Paragraph className="text-primary">
            En caso de que te suceda algo, nos contactaremos con él/ella, en el botón + puedes
            añadir contactos
          </Paragraph>
        </Row>
        {data && data.contacts ? (
          data.contacts.map(d => (
            <Row
              className="background-primary-medium"
              key={d.id}
              justify="space-around"
              align="middle">
              <Col push={1} xs={4} sm={4} md={6} lg={8} xl={10}>
                <Title level={2} style={{ marginTop: 10 }}>
                  <UserOutlined className="icon-color-primary" />
                </Title>
              </Col>
              <Col push={1} xs={16} sm={16} md={12} lg={8} xl={4}>
                <Text strong>{d.fullname}</Text> <br /> {d.email} <br /> {d.phone}
              </Col>
              <Col xs={4} sm={4} md={4} lg={8} xl={10}>
                <Switch onChange={() => toggleContact(d)} checked={checkSelected(d)} />
              </Col>
            </Row>
          ))
        ) : (
          <Row justify="center">
            <img src={EmptyState} alt="emptyState"></img>
            <Paragraph className="text-primary" level={5}>
              Aún no has registrado contactos, añadir uno nuevo
            </Paragraph>
          </Row>
        )}

        <br />
        <Row justify="center">
          <Button
            type="primary"
            shape="circle"
            onClick={() => {
              // putContact('primary');
              setShowCF(!showCF);
            }}
            icon={<PlusOutlined />}
          />
        </Row>
        <br />
        {showCF && (
          <Row className="show-block-long">
            <Text className="text-primary">Agregar un contacto nuevo</Text> <br></br>
            <Form name="add_contact" onFinish={putContact}>
              <Form.Item
                name="fullname"
                rules={[{ required: true, message: 'Por favor ingrese un nombre válido' }]}>
                <Input
                  className="form-item"
                  prefix={<UserOutlined className="icon-color-primary" />}
                  bordered={false}
                  placeholder="Nombre Contacto"
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Por favor ingrese su un email válido' }]}>
                <Input
                  className="form-item"
                  prefix={<MailOutlined className="icon-color-primary" />}
                  placeholder="email"
                  bordered={false}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  { required: true, message: 'Por favor ingrese un número de teléfono válido' },
                ]}>
                <Input
                  className="form-item"
                  prefix={<PhoneOutlined className="icon-color-primary" />}
                  placeholder="Celular"
                  bordered={false}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="address"
                rules={[{ required: true, message: 'Por favor ingrese una dirección válida' }]}>
                <Input.TextArea
                  className="form-item"
                  prefix={<LockOutlined />}
                  placeholder="Dirección"
                  bordered={false}
                  size="large"
                />
              </Form.Item>
              <Form.Item>
                <Row glutter={[8, 0]} justify="space-between">
                  <Col span={11}>
                    <Button type="primary" size="large" htmlType="submit" loading={loading} block>
                      Guardar
                    </Button>
                  </Col>
                  <Col span={11}>
                    <Button size="large" block>
                      Cancelar
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Row>
        )}
      </Content>
    </Layout>
  );
};

const GET_USER_CONTACTS = gql`
  query MyContacts($id: ID) {
    contacts(where: { user: $id }) {
      address
      email
      fullname
      id
      user {
        id
        email
      }
    }
  }
`;

const SAVE_USER_CONTACT = gql`
  mutation createContact($contact: createContactInput) {
    createContact(input: $contact) {
      contact {
        fullname
        phone
        id
        address
      }
    }
  }
`;
export default ContactPage;
