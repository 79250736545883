import React from 'react';
import { Row, Col, Button, Space, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
const { Text } = Typography;

const Route = () => {
  const { locations = {} } = useSelector(state => state);
  console.log(locations);

  const selectFrom = () => {
    navigate('/locations',  { state: { location: 'from', title: 'Seleccione el origen' } });
  };

  const selectTo = () => {
    navigate('/locations',  { state: { location: 'to', title: 'Seleccione el destino' } });
  };

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Row>
        <Col span={24}>
          <Text strong>Origen</Text>
          <Button size="large" type="primary" block onClick={() => selectFrom()}>
            {locations.from ? <Text> {locations.from.name}</Text> : 'Seleccionar origen'}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Text strong>Destino</Text>
          {locations.from ?
            <Button size="large" type="primary" block onClick={() => selectTo()}>
              {locations.to ? <Text>{locations.to.name}</Text> : 'Seleccionar destino'}
            </Button> :
            <Button size="large" type="ghost" block>Seleccionar destino</Button>
          }
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <br></br>
          <Button size="large" type="primary" block>
            Ver otras rutas
          </Button>
        </Col>
      </Row>
    </Space>
  );
};

export default Route;
