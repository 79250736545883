import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import './config-travel.scss';
import { Link } from 'gatsby';
import OrigenImg from '../../images/origen.svg';
import DestinoImg from '../../images/destino.svg';
import { Row, Col, Divider, Select, DatePicker, Typography } from 'antd';
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  ClockCircleTwoTone,
  EnvironmentTwoTone,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

const { Text } = Typography;
const { Option } = Select;

const ConfigTravel = () => {
  const [currentFrom, setFrom] = useState({});
  const [currentTo, setTo] = useState({});
  const dispatch = useDispatch();

  const locations = useSelector(state => state.locations) || {};
  const travelDate = useSelector(state => state.travelDate) || null;
  const route = useSelector(state => state.route);
  useEffect(() => {
    setFrom(locations.from || {});
    setTo(locations.to || {});
  }, []);

  const toggle = () => {
    const aux = currentFrom;
    dispatch({
      type: 'SET_LOCATION',
      data: {
        from: currentTo,
        to: currentFrom,
      },
    });

    setFrom(currentTo);
    setTo(aux);
  };

  const setTravelDate = td => {
    dispatch({
      type: 'SET_DATE',
      data: td,
    });
  };

  const setTransport = t => {
    dispatch({
      type: 'SET_TRANSPORT',
      data: t,
    });
  };
  return (
    <Row align="bottom" className="content-config-travel">
      <Col span={24}>
        <Row justify="center">
          <Col span={20}>
            <div className="locations">
              <div className="location">
                <Link
                  to="/locations"
                  state={{
                    location: 'from',
                    title: 'Selectione el origen',
                  }}
                >
                  <img src={OrigenImg} alt="origen"></img> Desde:{' '}
                  {currentFrom.name}
                </Link>
              </div>
              <div className="location">
                <Link
                  to="/locations"
                  state={{
                    location: 'to',
                    title: 'Selectione el destino',
                  }}
                >
                  <img src={DestinoImg} alt="destino"></img> Hasta:{' '}
                  {currentTo.name}
                </Link>
              </div>
            </div>
          </Col>
          <Col span={4} className="arrows" onClick={toggle}>
            <ArrowUpOutlined className="switch-arrow" />
            <ArrowDownOutlined className="switch-arrow" />
          </Col>
        </Row>
        {route && (
          <Row>
            <Col span={6}>
              <div>
                <EnvironmentTwoTone className="space-icons" />
                {Math.ceil(route.distance / 1000)} km
              </div>
            </Col>
            <Col span={6}>
              <div>
                <ClockCircleTwoTone className="space-icons" />
                {Math.ceil(route.duration / 3600)} h
              </div>
            </Col>
          </Row>
        )}
        <Divider className="divider-section" />
        <Row justify="space-between">
          <Col span={12}>
            <Text className="title" strong>
              Fecha
            </Text>
            <br />
            <DatePicker
              showTime
              placeholder="Fecha de viaje"
              inputReadOnly
              defaultValue={travelDate && moment(travelDate)}
              onChange={(value, dateString) => {
                console.log('Selected Time: ', value);
                console.log('Formatted Selected Time: ', dateString);
              }}
              onOk={value => {
                console.log('onOk: ', value);
                setTravelDate(value);
              }}
            />
          </Col>
          <Col span={10}>
            <Row>
              <Col span={12} offset={2}>
                <Text className="title" strong>
                  Viajo en
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {/* PV I don't like the width here but no time to find a workaround*/}
                <Select
                  placeholder="Transporte"
                  style={{ width: 120 }}
                  onSelect={value => setTransport(value)}
                >
                  <Option value="car">Carro</Option>
                  <Option value="bus">Bus</Option>
                  <Option value="avion">Moto</Option>
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ConfigTravel.propTypes = {
  config: PropTypes.object,
};

export default ConfigTravel;
