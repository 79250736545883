import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './map.scss';
import { useSelector } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as _ from 'lodash';

const Map = ({ className, padding, dragpan = true }) => {
  const [sourceRoute, setSourceRoute] = useState();
  const [isRouteAdded, setIsRouteAdded] = useState(false);
  const [isPointAdded, setIsPointAdded] = useState(false);
  const [map, setMap] = useState(false);
  const [sourceSelectedLocation, setSourceSelectedLocation] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { route, config } = useSelector(state => state);

  const mapNode = useRef(null);

  const setRoute = () => {
    if (route) {
      const coordinates = route && route.geometry.coordinates;
      if (sourceRoute && !isRouteAdded) {
        const feature = {
          type: 'Feature',
          geometry: route && route.geometry,
        };
        sourceRoute.setData(feature);
        setSourceRoute(sourceRoute);
        setIsRouteAdded(true);

        if (coordinates) {
          const bounds = coordinates.reduce((bounds, coord) => {
            return bounds.extend(coord);
          }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
          if (map) {
            map.fitBounds(bounds, {
              padding: padding,
            });
          }
        }
      }

      if (sourceSelectedLocation && !isPointAdded) {
        const selectedFeatures = [];
        const from = _.first(coordinates);

        if (from) {
          selectedFeatures.push({
            properties: {
              type: 'from',
            },
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: from,
            },
            id: 'from',
          });
        }

        const to = _.last(coordinates);

        if (to) {
          selectedFeatures.push({
            properties: {
              type: 'to',
            },
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: to,
            },
            id: 'to',
          });
        }

        if (sourceSelectedLocation && selectedFeatures.length) {
          sourceSelectedLocation.setData({
            type: 'FeatureCollection',
            features: selectedFeatures,
          });

          setSourceSelectedLocation(sourceSelectedLocation);
          setIsPointAdded(true);
        }
      }
    }
  };

  const renderMap = () => {
    if (config && config.MAPBOX_TOKEN && !map && !isLoading && !!mapNode.current) {
      setIsLoading(true);
      mapboxgl.accessToken = config.MAPBOX_TOKEN;

      const map = new mapboxgl.Map({
        container: mapNode.current,
        style: config.MAPBOX_STYLE,
        dragPan: dragpan,
        flyTo: false,
        maxZoom: 20,
        minZoom: 7
      });

      map.on('load', () => {
        map.loadImage(config.fromLocationIcon, (error, from) => {
          if (from) {
            map.addImage('from-location', from);
          }
          map.loadImage(config.toLocationIcon, (error, to) => {
            if (to) {
              map.addImage('to-location', to);
            }

            map.addSource('route', {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: [],
              },
            });

            map.addLayer({
              id: 'route',
              source: 'route',
              type: 'line',
              paint: {
                'line-width': 4,
                'line-color': 'blue',
              },
            });

            map.addSource('slocation', {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: [],
              },
            });

            map.addLayer({
              id: 'slocation',
              type: 'symbol',
              source: 'slocation',
              layout: {
                'icon-image': {
                  property: 'type',
                  type: 'categorical',
                  stops: [
                    ['from', 'from-location'],
                    ['to', 'to-location'],
                  ],
                },
                'icon-size': {
                  stops: [
                    [1, 0.1],
                    [5, 0.1],
                    [10, 0.1],
                    [16, 0.1],
                    [17, 0.1],
                    [20, 0.1],
                  ],
                },
                'icon-allow-overlap': true,
              },
            });

            setSourceRoute(map.getSource('route'));
            setSourceSelectedLocation(map.getSource('slocation'));
          });
        });
      });

      setMap(map);
      setIsLoading(false);
    }
  };

  renderMap();
  setRoute();

  useEffect(() => {
    renderMap();
    setRoute();
    // when this component is destroyed, remove the map
    return () => {
      if (map) {
        map.remove();
      }
    };
  }, []);

  return (
    <div>
      <div className={className} ref={mapNode} />
    </div>
  );
};

Map.propTypes = {
  className: PropTypes.string,
  padding: PropTypes.object,
  dragpan: PropTypes.bool
};

export default Map;
